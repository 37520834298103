import React, { useState } from 'react';
import { storage, ref, uploadBytes, getDownloadURL, deleteObject, db, collection, addDoc } from '../config/firebaseConfig';
import styled from 'styled-components';

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 40px auto;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 30px;
`;

const Form = styled.form`
  width: 100%;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const coreOptions = [
  { value: '3do', label: '3DO' },
  { value: 'arcade', label: 'Arcade' },
  { value: 'atari2600', label: 'Atari 2600' },
  { value: 'atari7800', label: 'Atari 7800' },
  { value: 'jaguar', label: 'Atari Jaguar' },
  { value: 'lynx', label: 'Atari Lynx' },
  { value: 'gba', label: 'Game Boy Advance' },
  { value: 'gb', label: 'Gameboy | Color' },
  { value: 'msx', label: 'MSX' },
  { value: 'ngp', label: 'Neo Geo Poket' },
  { value: 'nes', label: 'NES / Famicon' },
  { value: 'n64', label: 'Nintendo 64' },
  { value: 'nds', label: 'Nintendo DS' },
  { value: 'psx', label: 'Playstation' },
  { value: 'sega32x', label: 'Sega 32X' },
  { value: 'segaCD', label: 'Sega CD' },
  { value: 'segaGG', label: 'Sega Game Gear' },
  { value: 'segaMS', label: 'Sega Master System' },
  { value: 'segaMD', label: 'Sega Mega Drive' },
  { value: 'segaSaturn', label: 'Sega Saturn' },
  { value: 'snes', label: 'SNES' },
  { value: 'pce', label: 'TurboGrafs-16 | PC Engine' },
  { value: 'vb', label: 'Virtual Boy' },
  { value: 'ws', label: 'Wanderswan | Color' }
];

const UploadPage = () => {
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [description, setDescription] = useState('');
  const [core, setCore] = useState('');
  const [romFile, setRomFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRomChange = (e) => setRomFile(e.target.files[0]);
  const handleImageChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!romFile || !imageFile || !title || !description || !titleEn || !core) {
      alert("All fields are required!");
      return;
    }

    setLoading(true);
    let romRef, imageRef;

    try {
      // Upload ROM file
      romRef = ref(storage, romFile.name);
      await uploadBytes(romRef, romFile);
      const romUrl = await getDownloadURL(romRef);

      // Upload Image file
      imageRef = ref(storage, `image/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);

      // Save metadata to Firestore
      await addDoc(collection(db, "games"), {
        title: title,
        titleEn: titleEn,
        description: description,
        core: core,
        image: imageUrl,
        rom: romUrl,
      });

      alert("Upload successful!");
      setTitle('');
      setTitleEn('');
      setDescription('');
      setCore('');
      setRomFile(null);
      setImageFile(null);
    } catch (error) {
      console.error("Error uploading files: ", error);
      alert("Upload failed. Rolling back changes.");

      if (romRef) {
        deleteObject(romRef).catch((err) => console.error("Error deleting ROM file:", err));
      }
      if (imageRef) {
        deleteObject(imageRef).catch((err) => console.error("Error deleting Image file:", err));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <UploadContainer>
      <Title>Upload Game</Title>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label>Title</Label>
          <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter game title" />
        </InputGroup>
        <InputGroup>
          <Label>English Title</Label>
          <Input type="text" value={titleEn} onChange={(e) => setTitleEn(e.target.value)} placeholder="Enter game title in English" />
        </InputGroup>
        <InputGroup>
          <Label>Description</Label>
          <Input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter game description" />
        </InputGroup>
        <InputGroup>
          <Label>Core</Label>
          <Select value={core} onChange={(e) => setCore(e.target.value)}>
            <option value="">Select a core</option>
            {coreOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup>
          <FileInput type="file" id="romFile" onChange={handleRomChange} />
          <FileInputLabel htmlFor="romFile">
            {romFile ? romFile.name : "롬파일선택"}
          </FileInputLabel>
        </InputGroup>
        <InputGroup>
          <FileInput type="file" id="imageFile" onChange={handleImageChange} />
          <FileInputLabel htmlFor="imageFile">
            {imageFile ? imageFile.name : "이미지파일선택"}
          </FileInputLabel>
        </InputGroup>
        <Button type="submit">Upload</Button>
      </Form>
      {loading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </UploadContainer>
  );
};

export default UploadPage;