import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled.header`
  background: linear-gradient(to right, #2c3e50, #3498db);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #ecf0f1;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  
  img {
    height: 40px;
    margin-right: 10px;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const NavItem = styled.div`
  position: relative;
  margin: 0 15px;
  padding: 10px 15px;
  cursor: pointer;
  color: #ecf0f1;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 5px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const UserIcon = styled.div`
  background: url('/path/to/user-icon.png') no-repeat center center;
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ecf0f1;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255,255,255,0.5);
  }
`;

const isAdminMode = process.env.REACT_APP_ADMIN_MODE === 'true';

const HeaderComp = () => {
  const navigate = useNavigate();

  const handleNav = (path) => {
    if (path === "/") {
      // Home으로 이동할 때 페이지 새로고침
      window.location.href = "/";
    } else {
      navigate(path);
    }
  };

  return (
    <Header>
      <Logo onClick={() => handleNav("/")}>
        <span>Retro Games Hub</span>
      </Logo>
      {isAdminMode && (
        <Nav>
          <NavItem onClick={() => handleNav("/")}>
            Home
          </NavItem>
          <NavItem onClick={() => handleNav("/edit-list")}>
            Edit
          </NavItem>
          <NavItem onClick={() => handleNav("/upload")}>
            Upload
          </NavItem>
        </Nav>
      )}
    </Header>
  );
};

export default HeaderComp;