import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { db, collection, getDocs, deleteDoc, doc, updateDoc } from '../config/firebaseConfig';
import WebPImage from './WebPImage';
import Pagination from './Pagination';
import { storage, ref, deleteObject } from '../config/firebaseConfig';

const GameListContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const FilterButton = styled.button`
  padding: 10px;
  margin-right: 10px;
  background-color: #f00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c00;
  }
`;

const GameItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
`;

const GameInfo = styled.div`
  display: flex;
  align-items: center;
`;

const GameDetails = styled.div`
  margin-left: 10px;
`;

const GameTitle = styled.div`
  font-weight: bold;
`;

const GameGenre = styled.div`
  color: #666;
`;

const Button = styled.button`
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
`;

const SearchButton = styled.button`
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

const ResetButton = styled(SearchButton)`
  background-color: #f44336;
  border-radius: 5px;
  margin-left: 10px;

  &:hover {
    background-color: #d32f2f;
  }
`;

const GameEditList = () => {
  const [games, setGames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGames, setFilteredGames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGames();
  }, []);

  const fetchGames = async () => {
    const querySnapshot = await getDocs(collection(db, "games"));
    const gamesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setGames(gamesList);
    setFilteredGames(gamesList);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    const filtered = games.filter(game => 
      game.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredGames(filtered);
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilteredGames(games);
    setCurrentPage(1);
  };

  const indexOfLastGame = currentPage * gamesPerPage;
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  const currentGames = filteredGames.slice(indexOfFirstGame, indexOfLastGame);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleDelete = async (gameId, imagePath, romPath) => {
    if (window.confirm('이 게임을 삭제하시겠습니까?')) {
      try {
        await deleteDoc(doc(db, "games", gameId));
        const imageRef = ref(storage, imagePath);
        await deleteObject(imageRef);
        const romRef = ref(storage, romPath);
        await deleteObject(romRef);

        const updatedGames = games.filter(game => game.id !== gameId);
        setGames(updatedGames);
        setFilteredGames(updatedGames);
      } catch (error) {
        console.error("게임 삭제 중 오류 발생:", error);
        alert("게임 삭제에 실패했습니다.");
      }
    }
  };

  const handleEdit = (game) => {
    navigate(`/edit/${game.id}`, { state: { game } });
  };

  return (
    <GameListContainer>
      <SearchContainer>
        <SearchInput
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="제목으로 검색"
        />
        <SearchButton onClick={handleSearch}>검색</SearchButton>
        <ResetButton onClick={handleReset}>초기화</ResetButton>
      </SearchContainer>
      {currentGames.map((game) => (
        <GameItem key={game.id}>
          <GameInfo>
            <WebPImage imagePath={game.image} alt={game.title} width={'50px'} height={'50px'} />
            <GameDetails>
              <GameTitle>{game.title}</GameTitle>
              <GameGenre>{game.genre}</GameGenre>
            </GameDetails>
          </GameInfo>
          <div>
            <Button onClick={() => handleEdit(game)}>수정</Button>
            <Button onClick={() => handleDelete(game.id, game.image, game.rom)}>삭제</Button>
          </div>
        </GameItem>
      ))}
      <Pagination
        gamesPerPage={gamesPerPage}
        totalGames={filteredGames.length}
        paginate={paginate}
      />
    </GameListContainer>
  );
};

export default GameEditList;