import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const AdContainer = styled.div`
  position: relative;
  width: 300px;
  height: 250px;
  margin: 0 auto;
`;

const TextContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #333;
  z-index: 1;
`;

const Ads = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://ads-partners.coupang.com/g.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      try {
        if (containerRef.current) {
          // 기존 내용을 비우고 새로운 div 요소를 추가
          containerRef.current.innerHTML = '';
          const adElement = document.createElement('div');
          adElement.style.position = 'absolute';
          adElement.style.top = '0';
          adElement.style.left = '0';
          adElement.style.zIndex = '2';
          containerRef.current.appendChild(adElement);

          new window.PartnersCoupang.G({
            "id": 802605,
            "template": "carousel",
            "trackingCode": "AF0776198",
            "width": "300",
            "height": "250",
            "tsource": "",
            "container": adElement
          });
        }
      } catch (e) {
        console.error('Coupang partners initialization error', e);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AdContainer ref={containerRef}>
      <TextContent>
      쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.
      </TextContent>
    </AdContainer>
  );
};

export default Ads;