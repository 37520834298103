import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { storage, ref, getDownloadURL, doc, db, getDoc } from '../config/firebaseConfig';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin: auto;
`;

const Container = styled.div`
  width: 640px;
  height: 480px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
`;

const EmulatorFrame = () => {
    const { id } = useParams();
    const [game, setGame] = useState(null);
    const [romUrl, setRomUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchGameAndRom = useCallback(async () => {
        try {
            setLoading(true);
            const gameDocRef = doc(db, "games", id);
            const gameDoc = await getDoc(gameDocRef);
            
            if (gameDoc.exists()) {
                const gameData = gameDoc.data();
                setGame(gameData);
                
                const url = await getDownloadURL(ref(storage, gameData.rom));
                setRomUrl(url);
            } else {
                setError("Game not found");
            }
        } catch (error) {
            console.error('Error fetching game data:', error);
            setError("Failed to load game data");
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchGameAndRom();
    }, [fetchGameAndRom]);

    const iframeSrc = useMemo(() => `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Emulator</title>
        <style>
            body, html {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            #game {
                width: 640px;
                height: 480px;
                max-width: 100%;
            }
        </style>
    </head>
    <body>
        <div id="game"></div>
        <script>
            EJS_player = "#game";
            EJS_AdUrl = "${window.location.origin}/ads"
            EJS_core = "${game?.core}";
            EJS_pathtodata = "https://cdn.emulatorjs.org/stable/data/";
            EJS_gameUrl = "${romUrl}";
        </script>
        <script src="https://cdn.emulatorjs.org/stable/data/loader.js"></script>
    </body>
    </html>
    `, [game, romUrl]);

    if (loading) return (
        <Container>
            <LoadingSpinner />
        </Container>
    );
    
    if (error) return (
        <Container>
            <ErrorMessage>Error: {error}</ErrorMessage>
        </Container>
    );

    return (
        <Container>
            {romUrl && game ? (
                <iframe
                    srcDoc={iframeSrc}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="Emulator"
                    sandbox="allow-scripts allow-same-origin"
                />
            ) : (
                <ErrorMessage>Game data not available</ErrorMessage>
            )}
        </Container>
    );
};

export default EmulatorFrame;