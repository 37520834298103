import React from 'react';
import Emulator from './components/Emulator';
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import GameList from './components/GameList';
import GameDetails from './components/GameDetails';
import UploadPage from './components/UploadPage';
import GameEditList from './components/GameEditList';
import EditGame from './components/EditGame';
import EmulatorFrame from './components/EmulatorFrame';
import Ads from './pages/Ads';

const isAdminMode = process.env.REACT_APP_ADMIN_MODE === 'true';

function App() {
  const isHeaderVisible = !(window.location.pathname.startsWith('/frame') || window.location.pathname.startsWith('/ads'));
  
  return (
    <Router>
      <div className="App">
        {isHeaderVisible && <Header />}
        <Routes>
          <Route path='/ads' element={<Ads />} />
          <Route path="/frame/:id" element={<EmulatorFrame />} />
          {isAdminMode && (
            <>
              <Route path="/edit-list" element={<GameEditList />} />
              <Route path="/edit/:id" element={<EditGame />} />
              <Route path="/upload" element={<UploadPage />} />
            </>
          )}
          <Route path="/game/:id" element={<GameDetails />} />
          <Route path="/" element={<GameList />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;