import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { db, doc, getDoc, updateDoc, storage, ref, uploadBytes, getDownloadURL, deleteObject } from '../config/firebaseConfig';
import styled from 'styled-components';

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const EditGame = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { game } = location.state;

  const [title, setTitle] = useState(game.title);
  const [titleEn, setTitleEn] = useState(game.titleEn);
  const [description, setDescription] = useState(game.description);
  const [core, setCore] = useState(game.core);
  const [romFile, setRomFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [romUrl, setRomUrl] = useState(game.rom);
  const [imageUrl, setImageUrl] = useState(game.image);

  const handleRomChange = (e) => setRomFile(e.target.files[0]);
  const handleImageChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newRomUrl = romUrl;
    let newImageUrl = imageUrl;

    try {
      // ROM 파일 변경 시
      if (romFile) {
        const romRef = ref(storage, romUrl);
        await deleteObject(romRef);

        const newRomRef = ref(storage, romFile.name);
        await uploadBytes(newRomRef, romFile);
        newRomUrl = await getDownloadURL(newRomRef);
      }

      // 이미지 파일 변경 시
      if (imageFile) {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);

        const newImageRef = ref(storage, `image/${imageFile.name}`);
        await uploadBytes(newImageRef, imageFile);
        newImageUrl = await getDownloadURL(newImageRef);
      }

      // Firestore 문서 업데이트
      await updateDoc(doc(db, "games", id), {
        title: title,
        titleEn: titleEn,
        description: description,
        core: core,
        rom: newRomUrl,
        image: newImageUrl,
      });

      alert("수정 완료!");
      navigate('/edit-list');
    } catch (error) {
      console.error("수정 중 오류 발생:", error);
      alert("수정 실패. 다시 시도해 주세요.");
    }
  };

  return (
    <EditContainer>
      <h2>게임 수정</h2>
      <form onSubmit={handleSubmit}>
        <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
        <Input type="text" value={titleEn} onChange={(e) => setTitleEn(e.target.value)} placeholder="TitleEn" />
        <Input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        <Input type="text" value={core} onChange={(e) => setCore(e.target.value)} placeholder="Core" />
        <Input type="file" onChange={handleRomChange} />
        <Input type="file" onChange={handleImageChange} />
        <Button type="submit">수정</Button>
      </form>
    </EditContainer>
  );
};

export default EditGame;