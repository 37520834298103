import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-family: Arial, sans-serif;
`;

const PageButton = styled.button`
  background-color: transparent;
  border: none;
  color: #333;
  margin: 0 5px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.active {
    background-color: #007bff;
    color: white;
  }
`;

const PageNumber = styled(PageButton)`
  min-width: 40px;
`;

const NavButton = styled(PageButton)`
  font-weight: bold;
  font-size: 16px;
  padding: 8px 16px;
  
  &:hover {
    background-color: #e0e0e0;
  }
`;

const Ellipsis = styled.span`
  margin: 0 5px;
`;

const Pagination = ({ gamesPerPage, totalGames, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalGames / gamesPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const displayPages = [];
    if (totalPages <= 7) {
      return pageNumbers.map(number => (
        <PageNumber
          key={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? 'active' : ''}
        >
          {number}
        </PageNumber>
      ));
    }

    displayPages.push(
      <PageNumber
        key={1}
        onClick={() => paginate(1)}
        className={currentPage === 1 ? 'active' : ''}
      >
        1
      </PageNumber>
    );

    if (currentPage > 3) {
      displayPages.push(<Ellipsis key="ellipsis1">...</Ellipsis>);
    }

    const start = Math.max(2, currentPage - 1);
    const end = Math.min(totalPages - 1, currentPage + 1);

    for (let i = start; i <= end; i++) {
      displayPages.push(
        <PageNumber
          key={i}
          onClick={() => paginate(i)}
          className={currentPage === i ? 'active' : ''}
        >
          {i}
        </PageNumber>
      );
    }

    if (currentPage < totalPages - 2) {
      displayPages.push(<Ellipsis key="ellipsis2">...</Ellipsis>);
    }

    displayPages.push(
      <PageNumber
        key={totalPages}
        onClick={() => paginate(totalPages)}
        className={currentPage === totalPages ? 'active' : ''}
      >
        {totalPages}
      </PageNumber>
    );

    return displayPages;
  };

  return (
    <PaginationContainer>
      <NavButton onClick={() => paginate(1)} disabled={currentPage === 1} title="First Page">
        &laquo;
      </NavButton>
      <NavButton onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} title="Previous Page">
        &lsaquo;
      </NavButton>
      {renderPageNumbers()}
      <NavButton onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} title="Next Page">
        &rsaquo;
      </NavButton>
      <NavButton onClick={() => paginate(totalPages)} disabled={currentPage === totalPages} title="Last Page">
        &raquo;
      </NavButton>
    </PaginationContainer>
  );
};

export default Pagination;