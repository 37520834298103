import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { db, storage, collection, getDocs, ref, getDownloadURL } from '../config/firebaseConfig';
import Pagination from './Pagination';

const GameListContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  width: 300px;
`;

const SearchButton = styled.button`
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const ResetButton = styled(SearchButton)`
  background-color: #f44336;
  border-radius: 5px;
  margin-left: 10px;

  &:hover {
    background-color: #d32f2f;
  }
`;

const GameItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const GameInfo = styled.div`
  display: flex;
  align-items: center;
`;

const GameDetails = styled.div`
  margin-left: 20px;
`;

const GameTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const GameGenre = styled.div`
  color: #666;
  font-size: 14px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// 이미지 URL 캐시를 위한 객체
const imageUrlCache = {};

const GameList = () => {
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchGames();
  }, []);

  const fetchGames = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, "games"));
    const gamesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setGames(gamesList);
    setFilteredGames(gamesList);
    setLoading(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    const filtered = games.filter(game => 
      game.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredGames(filtered);
    setCurrentPage(1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilteredGames(games);
    setCurrentPage(1);
  };

  const indexOfLastGame = currentPage * gamesPerPage;
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  const currentGames = filteredGames.slice(indexOfFirstGame, indexOfLastGame);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleGameClick = (game) => {
    navigate(`/game/${game.id}`, { state: { game: game } });
  };

  const getImageUrl = useCallback(async (imagePath) => {
    if (imageUrlCache[imagePath]) {
      return imageUrlCache[imagePath];
    }

    try {
      const imageRef = ref(storage, imagePath);
      const url = await getDownloadURL(imageRef);
      imageUrlCache[imagePath] = url;
      return url;
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return ''; // 에러 시 빈 문자열 반환 또는 기본 이미지 URL 반환
    }
  }, []);

  const GameImage = ({ imagePath, alt }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
      getImageUrl(imagePath).then(setImageUrl);
    }, [imagePath]);

    return (
      <img
        src={imageUrl}
        alt={alt}
        width='80px'
        height='80px'
        loading="lazy"
        style={{ borderRadius: '8px' }}
      />
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <GameListContainer>
      <SearchContainer>
        <SearchInput
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder="게임 제목으로 검색"
        />
        <SearchButton onClick={handleSearch}>검색</SearchButton>
        <ResetButton onClick={handleReset}>초기화</ResetButton>
      </SearchContainer>
      {currentGames.map((game) => (
        <GameItem key={game.id} onClick={() => handleGameClick(game)}>
          <GameInfo>
            <GameImage imagePath={game.image} alt={game.title} />
            <GameDetails>
              <GameTitle>{game.title}</GameTitle>
              <GameGenre>{game.genre}</GameGenre>
            </GameDetails>
          </GameInfo>
        </GameItem>
      ))}
      <Pagination
        gamesPerPage={gamesPerPage}
        totalGames={filteredGames.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </GameListContainer>
  );
};

export default GameList;