import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import EmulatorFrame from './EmulatorFrame';
import { storage, ref, getDownloadURL } from '../config/firebaseConfig';
import styled from 'styled-components';
import WebPImage from './WebPImage';

// const DetailsContainer = styled.div`
//   padding: 20px;
//   background-color: #fff;
//   border-radius: 5px;
//   box-shadow: 0 0 10px rgba(0,0,0,0.1);
// `;

// const GameTitle = styled.h1`
//   font-size: 24px;
//   margin-bottom: 10px;
// `;

const ImageContainer = styled.div`
  width: 640px;
  height: 480px;
  position: relative;
  text-align: center;
`;

const StartButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const GameTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
`;

// const StartButton = styled.button`
//   display: block;
//   margin: 10px auto;
//   padding: 10px 20px;
//   background-color: #333;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   &:hover {
//     background-color: #555;
//   }
// `;

const EmulatorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const GameDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const { game } = location.state;

    const [isGameStarted, setIsGameStarted] = useState(false);
    const [romUrl, setRomUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRomUrl = async () => {
            try {
                const url = await getDownloadURL(ref(storage, game.rom));
                setRomUrl(url);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching ROM URL:', error);
                setLoading(false);
            }
        };
        fetchRomUrl();
    }, [id]);

    const handleStartGame = () => {
        setIsGameStarted(true);
    };

    return (
        <DetailsContainer>
           <EmulatorContainer>
          <EmulatorFrame romUrl={romUrl} core={game.core} />
          </EmulatorContainer>
      </DetailsContainer>
    );
};

export default GameDetails;